import React, {useContext, useState} from 'react'
import {Link, navigate} from 'gatsby'
import {useTranslation} from 'react-i18next'
import {Context as ProfileContext} from 'src/context/ProfileContext'
import useLocalizedRoutes from 'src/hooks/useLocalizedRoutes'
import {usePageContext} from 'src/context/PageContext'
import Toast from 'src/components/atoms/Toast'
import {ArrowedLink} from 'src/components/buttons/Link'
import Arrow from 'src/components/icons/Arrow'
import PrimaryButton from 'src/components/buttons/Primary'
import {DefaultAvatarLogo} from 'src/components/atoms/DefaultAvatar'

export default function CardTeacherSearch({teacher}) {
    const {t} = useTranslation();
    const [imgError, setImgError] = useState(false);
    const {teacherProfile, bookTeacher, bookTrial } = useLocalizedRoutes();
    const {state: {profile}} = useContext(ProfileContext);
    const {lang} = usePageContext();

    function getStartingValue() {
        let hourLessons = [];
        let excludedTrial = teacher.lesson_plans.filter(lessonType => !lessonType.is_trial);
        excludedTrial.map((lesson) => {
            if (lesson.lesson_duration_minutes === 60) { hourLessons.push(lesson); }
        });
        let prices = hourLessons
            .map((lesson) => Math.round(lesson.discounted_total_price_for_students.value))
            .sort((a, b) => a - b);



        return prices[0]
    }

    function renderBookingButtons() {
        if(!profile?.teacher_profile) {
            return (
                <div className="flex items-center mt-4">
                    <PrimaryButton
                        onClick={() => navigate(`${teacherProfile}/${teacher.id}`)}
                        className="mr-4"
                    >{t('findTeacher.details')}</PrimaryButton>
                    {teacher.lesson_plans.find(lesson => lesson.is_trial === true) && (
                        <span className="text-base text-grey-800 font-semibold">{t('teacherProfile.trialAvailable')}</span>
                    )}
                </div>
            )
        }
        return null
    }

    return (
        <div className="my-8 md:my-16">
            <div className="flex items-center">
                <div className="w-3/12 lg:w-1/4 lg:w-auto mr-4 md:mr-8 lg:mr-12 pr-8">
                    {imgError ? (
                        <DefaultAvatarLogo />
                    ) : (
                        <div className="w-full h-0 pt-full relative rounded-full">
                            <img
                                src={teacher.profile_picture_url}
                                alt={teacher.first_name} onError={() => setImgError(true)}
                                className="object-cover rounded-full inset-0 absolute h-full w-full"
                            />
                        </div>
                    )}
                </div>
                <div className="flex-1 pt-3 md:pt-0">
                    <h2 className="font-headers mb-2 lg:font-semibold text-xl lg:text-4xl">{teacher.first_name} {teacher.last_name}</h2>
                    <div className="flex flex-wrap">
                        {teacher.instruments.map((instrument, i) => (
                            <Toast key={i} text={instrument.translations[lang]}/>
                        ))}
                        <p className="ml-2 hidden md:block">
                            {t('findTeacher.startingFrom')} € {getStartingValue()}
                        </p>
                    </div>
                    {/* Large screens */}
                    <div className="hidden md:block">
                        <div className="mt-2 relative">
                            <p className="text-dark-blue-600 text-xl ellispis">{teacher.bio[lang]}</p>
                        </div>
                        {renderBookingButtons()}
                    </div>
                </div>
            </div>

            {/* Small screens */}
            <div className="md:hidden mt-3">
                <div className="my-2">
                    <p className="font-xs ellispis">{teacher.biography}</p>
                    <Link to={`${teacherProfile}/${teacher.id}`} className="text-baby-blue-600 underline">
                        {t('findTeacher.readMore')}
                    </Link>
                </div>
                <div className="flex justify-end">
                    <Link to={`${bookTeacher}/${teacher.id}`}>
                        <Arrow/>
                    </Link>
                </div>
            </div>
        </div>
    )
}
