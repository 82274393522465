import React from 'react'

export function Search(props) {
    return (
        <svg width={28} height={28} viewBox="0 0 28 28" {...props}>
            <title>{"search"}</title>
            <path
                d="M12.15 0C18.86 0 24.3 5.44 24.3 12.15c0 2.869-.994 5.506-2.657 7.584l5.962 5.961a1.35 1.35 0 01-1.8 2.008l-.11-.098-5.96-5.962A12.099 12.099 0 0112.15 24.3C5.44 24.3 0 18.86 0 12.15S5.44 0 12.15 0zm0 2.7a9.45 9.45 0 100 18.9 9.45 9.45 0 000-18.9z"
                fillRule="nonzero"
                fill="#FFF"
            />
        </svg>
    )
}

