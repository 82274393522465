import React from 'react'
import { useTranslation } from 'react-i18next'
import Heading from 'src/components/typography/Heading'

export default function FindTeacherHero(){
    const { t } = useTranslation();

    return (
        <div className="px-4 bg-baby-blue-600 w-full pt-6 pb-32 lg:pt-16 lg:pb-40">
            <div className="mx-auto xl:w-1/2 sm:w-11/12 text-center">
                <Heading type='head-title-wrap'>
                    <span className="text-white">{t('findTeacher.find')} </span>
                    <span className="text-egg-shell-600">{t('findTeacher.yourPersonal')} </span>
                    <span className="text-white">{t('findTeacher.musicTeacher')}</span>
                </Heading>
            </div>
        </div>
    )
}
