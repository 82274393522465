import React from 'react'
import PrimaryButton from 'src/components/buttons/Primary';
import { useTranslation } from 'react-i18next'

const INTRO = "md:text-lg text-grey-600";


export default function NoResults({resetFilters}) {
    const { t } = useTranslation();
    return (
        <div className="flex justify-between items-center flex-col h-full md:my-10">
            <h2 className="w-full lg:w-auto mb-2 font-bold lg:mb-0 font-titles text-dark-blue-600 text-2xl lg:text-4xl text-center">{t('findTeacher.noTeachersFound')}</h2>
            <div className="md:my-6 my-2">
                <p className={INTRO}>{t('findTeacher.noResults')}</p>
            </div>
            <PrimaryButton onClick={resetFilters}>{t('findTeacher.seeAll')}</PrimaryButton>
        </div>
    )
}
