import React from 'react'
import FadeIn from 'react-fade-in'
import ReactLoading from 'react-loading'
import Lottie from 'react-lottie'
import Heading from 'src/components/typography/Heading'
import * as computerDoingNothing from 'src/components/icons/no_results.json'

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: computerDoingNothing.default,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
    }
}

export function LottieLoader({loading}) {
    return loading ? (
        <FadeIn>
            <div className="container mx-auto flex justify-center items-center h-64 flex-col md:flex-row">
                <Heading type="sub-title" className="mx-2 text-dark-blue-600">Tuning teachers</Heading>
                <ReactLoading type={"bars"} color={"rgba(168, 208, 230, 1)"}/>
            </div>
        </FadeIn>
    ) : null
}

export function NoResultsLottie() {
    return (
        <FadeIn>
            <div className="container mx-auto flex justify-center flex-col items-center h-64">
                <Lottie options={defaultOptions} height={120} width={120}/>
                <Heading type="caption">No teachers found</Heading>
            </div>
        </FadeIn>
    )
}
