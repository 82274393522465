import React, { useState, useEffect } from 'react'
import FadeIn from 'react-fade-in'
import Heading from 'src/components/typography/Heading'
import CardTeacherSearch from 'src/components/cards/CardTeachSearch'
import {useTranslation} from 'react-i18next'
import Separator from 'src/components/atoms/Separator'
import NoResults from 'src/components/sections/findTeacher/NoResults'
import { OutlinedButton } from 'src/components/buttons/Primary'

export default function TeachersSearch({teachersList, resetFilters, changePage}) {
    const { t } = useTranslation();
    const [ loading, setLoading ] = useState(false);

    useEffect(() => {
        setLoading(false)
    },[teachersList])

    if (!teachersList.length) {
        return <NoResults resetFilters={resetFilters}/>
    }

    function loadMore() {
        setLoading(true)
        changePage()
    }

    return (
        <div className="container mx-auto my-6 md:my-20 px-6 xl:px-0">
            <Heading type='sub-title'>{t('findTeacher.teachers')}</Heading>
            {teachersList.map((teacher, index) => {
                return (
                    <div key={teacher.id}>
                        <CardTeacherSearch key={teacher.key} teacher={teacher}/>
                        {index !== teachersList.length - 1 && <Separator/>}
                    </div>
                )
            })}
            <div className="flex justify-center mt-4">
                <OutlinedButton
                    onClick={loadMore}
                    isLoading={loading}
                >
                    {t('general.loadMore')}
                </OutlinedButton>
            </div>
        </div>
    )
}
