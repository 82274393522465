import React, {useState, useReducer, useEffect } from 'react'
import {useTranslation} from 'react-i18next'
import classNames from 'classnames'
import Select from 'src/components/forms/Select'
import Label from 'src/components/forms/Label'
import useWindowSize from 'src/hooks/useWindowSize'
import {Search} from 'src/components/icons/Search'
import {usePageContext} from 'src/context/PageContext'
import {priceList} from 'src/data/listData'

const SELECT = 'w-full pb-6 lg:w-4/12 flex-grow md:mx-4';
const SHOW_MD = 'md:block';

const filtersReducer = (state, action) => {
    switch (action.type) {
        case 'set_filter':
            return { ...state, ...action.payload }
        case 'reset_filters':
            return {}
        default:
            return state
    }
}

export default function useSearchFilters(preSelectedInstrument, filterTeachers) {
    const {t} = useTranslation();
    const size = useWindowSize();
    const {translatedInstruments, translatedExperienceLevel, translatedLanguages} = usePageContext()
    const initialHiddenValue = size.width < 768 ? 2 : 0;
    const [state, dispatch] = useReducer(filtersReducer, {});
    const [hiddenFilters, setHiddenFilters] = useState(initialHiddenValue);

    useEffect(() => {
        if(preSelectedInstrument) {
            dispatch({
                type: 'set_filter',
                payload: {
                    selectedInstrument: preSelectedInstrument
                }
            })
        }
        //filterTeachers()
    }, [preSelectedInstrument])

    useEffect(() => {
        if(Object.keys(state).length !== 0) {
            filterTeachers()
        }
    }, [state])

    function setInReducer(key, obj) {
        dispatch({
            type: 'set_filter',
            payload: {
                [key]: obj
            }
        })
    }

    function resetFilters() {
        dispatch({
            type: 'reset_filters'
        })
    }

    function render() {
        const { selectedInstrument, selectedSkill, selectedPriceRange, selectedLanguage } = state;
        return (
            <div className="px-4">
                <div className="bg-dark-blue-600 container mx-auto rounded p-4 lg:p-8 pb-0 lg:pb-4 -mt-24 md:-mt-10 flex items-center flex-col md:flex-row">
                    <div className="flex-grow flex flex-col md:flex-row items-center md:pr-4 w-full">
                        <div className={classNames(SELECT)}>
                            <Select
                                data={translatedInstruments}
                                label={<Label>{t('findTeacher.instrument')}</Label>}
                                labelClasses="text-white"
                                onChange={(instrument) => setInReducer('selectedInstrument', instrument)}
                                value={selectedInstrument}
                                placeholder={t('findTeacher.select')}
                            />
                        </div>
                        <div className={classNames(SELECT)}>
                            <Select
                                data={translatedExperienceLevel}
                                label={t('findTeacher.skillLevel')}
                                labelClasses="text-white"
                                onChange={(skill) => setInReducer('selectedSkill', skill)}
                                value={selectedSkill}
                                placeholder={t('findTeacher.select')}
                            />
                        </div>
                        {hiddenFilters <= 0 &&
                        <div className={classNames(SELECT, SHOW_MD)}>
                            <Select
                                data={priceList}
                                label={t('findTeacher.priceRange')}
                                labelClasses="text-white"
                                onChange={(priceRange) => setInReducer('selectedPriceRange', priceRange)}
                                value={selectedPriceRange}
                                placeholder={t('findTeacher.select')}
                            />
                        </div>}
                        {hiddenFilters <= 1 && <div className={classNames(SELECT, SHOW_MD)}>
                            <Select
                                data={translatedLanguages}
                                label={t('findTeacher.language')}
                                labelClasses="text-white"
                                onChange={(language) => setInReducer('selectedLanguage', language)}
                                value={selectedLanguage}
                                placeholder={t('findTeacher.select')}
                            />
                        </div>}
                        {hiddenFilters > 1 && <Label
                            onClick={() => setHiddenFilters(hiddenFilters - 1)}
                            labelClasses="capitalize text-white cursor-pointer md:hidden text-base">
                            + {t('findTeacher.label')}
                        </Label>}
                    </div>
                    <button
                        className="bg-egg-shell-600 p-3 rounded cursor-pointer w-full md:w-12 h-12 flex justify-center items-center mb-4 md:mb-2 focus:outline-none"
                        onClick={() => filterTeachers()}
                    >
                        <Search />
                    </button>
                </div>
            </div>
        )
    }

    let filters = {
        instrument: state.selectedInstrument?.value,
        experience_level: state.selectedSkill?.value,
        min_lesson_price_eur: state.selectedPriceRange?.value.split(' ').shift(),
        max_lesson_price_eur: state.selectedPriceRange?.value.split(' ').pop(),
        language: state.selectedLanguage?.value
    };

    return {
        FilterComponent: render,
        filters,
        resetFilters
    }
}
