import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Context as ThemeContext } from 'src/context/ThemeContext'
import { Context as BookingContext } from 'src/context/BookingContext'
import MarketingLayout from 'src/components/layout/MarketingLayout'
import Hero from 'src/components/sections/findTeacher/Hero'
import TeachersSearchTable from 'src/components/tables/TeachersSearch'
import useSearchFilters from 'src/hooks/useSearchFilters'
import { useVisitorApi } from 'src/hooks/useApi'
import {LottieLoader} from 'src/components/atoms/LottieLoader'

const sleep = m => new Promise(r => setTimeout(r, m))

export default function FindTeacher(props) {
    const { location } = props;
    const { t } = useTranslation();
    const { setTheme } = useContext(ThemeContext);
    const { resetBookingData } = useContext(BookingContext);
    const [teachersList, setTeachersList] = useState([]);
    const [loading, setLoading] = useState(false);
    const { getAllTeachers } = useVisitorApi();
    const [ page, setPage ] = useState(1)
    let paramsInstrument;
    if (location.state) {
        paramsInstrument =  location.state.instrument
    }

    const { FilterComponent, filters, resetFilters } = useSearchFilters(preSelectedInstrument(), filterTeachers);

    async function getTeachers() {
        setLoading(page === 1);
        filters.page = page
        const { data } = await getAllTeachers(filters);
        let mergedList = teachersList.concat(data.data);
        setTeachersList(page === 1 ? data.data : mergedList);
        setLoading(false)
    }

    async function filterTeachers() {
        setLoading(true);
        setPage(1);
        filters.page = 1;
        await sleep(1000);
        const { data } = await getAllTeachers(filters);
        setTeachersList(data.data);
        setLoading(false)
    }

    async function cleanData() {
        await resetFilters();
        setPage(1);
        setLoading(true);
        const { data } = await getAllTeachers();
        setTeachersList(data.data);
        setLoading(false)
    }

    function loadMore () {
        setPage(page + 1)
    }

    useEffect(() => {
        setTheme('normal')
        resetBookingData()
    }, [])

    useEffect(() => {
        setPage(1)
    },[])

    useEffect(() => {
        getTeachers()
    }, [page])

    function preSelectedInstrument() {
        if(!paramsInstrument) return null
        else return paramsInstrument
    }

    return (
        <MarketingLayout {...props} title={t('findTeacher.title')}>
            <Hero/>
            <FilterComponent />
            { !loading && <TeachersSearchTable page={1} changePage={loadMore} filters={filters} teachersList={teachersList} resetFilters={cleanData}/> }
            { loading && <LottieLoader loading={loading}/>}
        </MarketingLayout>
    )
}
